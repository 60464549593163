import '/src/scss/style.scss';

if (import.meta.hot) {
	import.meta.hot.accept();
}

window.log = console.log;

import webfontloader from '@/plugins/webfontloader';
import detectTab from '@/utils/detectTab';
import activeLinks from '@/utils/activeLinks';
import localLinks from '@/utils/localLinks';
import 'lazysizes';

webfontloader();
detectTab();
activeLinks(window.location.pathname);
localLinks();

const splash = document.querySelector('[data-splash]');
const splashVideo = document.getElementById('video-splash');
const cheersVideo = document.getElementById('video-cheers');

splashVideo.addEventListener('ended', () => {
	splash.classList.add('curtains-up');

	setTimeout(() => {
		document.body.removeAttribute('style');
		splash.remove();

		if (cheersVideo) cheersVideo.play();
	}, 400);
});
